import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "./Button";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as constant from "./Constants/GlobalConstant";
//import '../config/private.json'

import "./App.css";
import "./login.css";
import LoginLogo from "./image_2021_06_29T12_08_04_189Z.png";
// import { Users } from './Users'

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  card: {
    top: "50%",
    position: "absolute",
    transform: "translateY(-50%)",
    background: "#083960",
    borderRadius: "0px",
    color: "#fff",
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const history = useHistory();
  var isLogin = false;
  sessionStorage.clear();
  const [ip, setIP] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [userFound, setUserFound] = useState(false);
  const [userStatus, SetUserStatus] = useState("");
  const [error, setError] = useState();

  /**
   * upon submission handle submit will authenticate
   * @param e
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    let userDetails = {
      data: {},
    };
    let userInput = {
      Username: username,
      Password: password,
    };

    axios
      .post(`${constant.EMBED_URL}/getEmbededReport`, userInput)
      .then((res) => {
        if (res.status === 200) {
          console.log("getEmbededReport", res.data);
          if (res && res.data && res.data["results"].length) {
            SetUserStatus(() => res.data["results"][0].CustomerLoginStatus);
            const status = res.data["results"][0].CustomerLoginStatus;
            if (res.data["results"][0].CustomerLoginStatus !== "Active") {
              setInvalid(false);
            }
            userDetails = {
              data: res.data["results"],
            };
            //history.push('/home', userDetails)
            submitLoginInfo(userDetails, status);
          } else {
            setInvalid(true);
          }
        } else {
          setInvalid(true);
        }
      })
      .catch((err) => {
        console.log("Error => Sharepoint service33==>", err);
        setError("Something went wrong, please try again later");
        setInvalid(false);
      });
  };

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data);
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  const submitLoginInfo = (userDetails, status) => {
    let userCredential = userDetails.data[0];
    let userInput = {
      UserID: userCredential.UserID,
      LoggedinTime: new Date(),
      MachineIPAddress: ip,
    };

    console.log("userInput", userInput);
    axios
      .post(`${constant.EMBED_URL}/submitLoginInfo`, userInput)
      .then((res) => {
        if (res.status === 200) {
          console.log("res", res.data);
          var LoginInfo = res.data["results"]["insertId"];
          sessionStorage.setItem("LoginInfo", JSON.stringify(LoginInfo));
          sessionStorage.setItem("username", userCredential.UserName);
          isLogin = true;
          sessionStorage.setItem("isLogin", isLogin);
          console.log("####", userStatus);
          if (status == "Active") {
            history.push("/home", userDetails);
          }
        } else {
          setInvalid(true);
        }
      })
      .catch((err) => {
        console.log("Error => Sharepoint service33==>", err);
        setInvalid(true);
      });
    // history.push('/home', userDetails)
  };
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Card className={classes.card}>
        <CardContent>
          <div className="login-logo">
            <img src={LoginLogo} className="logo-img" alt="logo" />
          </div>
          <form className={classes.form} onSubmit={handleSubmit}>
            {error && <p className="invalid">{error}</p>}
            {!userFound && invalid ? (
              <p className="invalid">* Invalid Username or password.</p>
            ) : (
              userStatus == "InActive" && (
                <p className="invalid">*User is inactive </p>
              )
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="Username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              InputProps={{
                className: "login-input",
              }}
              InputLabelProps={{
                style: { color: "#fff" },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              mb={2}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              InputProps={{
                className: "login-input",
              }}
              InputLabelProps={{
                style: { color: "#fff" },
              }}
            />
            <Grid container>
              <Grid item xs>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="medium"
                  mt={2}
                  className="login-button"
                >
                  LOGIN
                </Button>
              </Grid>
              <Grid item className="forget-grid">
                <Link
                  href="#"
                  onClick={() =>
                    (window.location =
                      "mailto:info@neobi.io?Subject=Change my password")
                  }
                  variant="body2"
                  className="login-forgot-password"
                  mt={3}
                >
                  Forgot My Password?
                </Link>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
}
